<template lang="pug">
  b-form#DeliveriesChangeDriverForm(@submit.stop.prevent="onSubmit")
    .ml-2.text-danger {{ errorDetail }}
    b-form-group(invalid-feedback="Este campo es requerido")
      .input-title Ids de Envíos
      b-form-input#id-list.input.w-100(
        v-model="form.idList"
        placeholder="Ej: 1234, 2353, 533..."
        :state="$v.$dirty ? !$v.form.idList.$invalid : null"
      )
    hr.linea
    b-form-group(invalid-feedback="Este campo es requerido")
      .input-title Conductor
      b-form-select#driver.input.w-100(
        v-model="form.driverId"
        :state="$v.$dirty ? !$v.form.driverId.$invalid : null"
      )
        b-form-select-option(:value="null") Selecciona un conductor
        b-form-select-option(v-for="driver in drivers" :value="driver.id") {{ driver.name }} {{ driver.lastName }}
    hr.linea
    .text-right
      b-button(type="submit" :disabled="loading") Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    ids: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      errorDetail: null,
      form: {
        idList: '',
        driverId: null
      }
    }
  },
  validations: {
    form: {
      idList: { required },
      driverId: { required }
    }
  },
  methods: {
    ...mapActions(['listUser', 'bulkUpdateDeliveryDriver']),
    async onSubmit () {
      this.loading = true
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        const response = await this.bulkUpdateDeliveryDriver({ data: this.data })
        if (response.status >= 200 && response.status < 300) {
          this.makeSuccessToast(response.data.message || 'El conductor se actualizó correctamente')
          this.$emit('done')
        } else if (response.status >= 400) {
          this.makeDangerToast(response.data.message || 'Error al cambiar el conductor')
          console.log(response)
          this.errorDetail = response.data.detail
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['drivers']),
    data () {
      let deliveriesIds = this.form.idList.replace(' ', '', 'g').split(',')
      deliveriesIds = deliveriesIds.map(id => id.trim())
      return {
        driverId: this.form.driverId,
        deliveriesIds
      }
    }
  },
  created () {
    this.form.idList = this.ids.join(', ')
    this.listUser()
  }
}
</script>
