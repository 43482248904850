const mutations = {
  'SET_STORES' (state, { stores }) {
    state.stores = stores
  },
  'SET_STORE' (state, { store }) {
    state.store = store
  }
}

export default mutations
