import axios from '../../plugins/axios'

export const listExceptions = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: payload ? payload.params : null
  }
  return axios.get('/exception', config)
    .then(response => {
      commit('SET_EXCEPTIONS', { exceptions: response.data })
      return response
    })
    .catch(err => err.response)
}

export const createException = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post('/exception', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const updateException = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/exception/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const deleteException = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.delete(`/exception/${payload.id}`, config)
    .then(response => response)
    .catch(err => err.response)
}
