<script>
import { mapActions } from 'vuex'
import MakeToastMixin from '../../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  methods: {
    ...mapActions(['bulkDeleteDelivery']),
    async onBulkDeleteDeliveryMixin (data) {
      const { deliveriesIds = [] } = data
      if (deliveriesIds.length === 0) {
        this.makeDangerToast('Debe seleccionar los envíos')
        return
      }
      const ok = await this.$root.$bvModal.msgBoxConfirm(`¿Está seguro que desea eleminar los envíos: ${deliveriesIds.join(', ')}?`)
      if (ok) {
        const response = await this.bulkDeleteDelivery({ data: data })
        if (response.status >= 200 && response.status < 300) {
          this.makeSuccessToast('Los envíos se eliminaron correctamente')
          response.ok = true
        } else if (response.status >= 400) {
          this.makeDangerToast('Error al eliminar los envíos')
          response.ok = false
        }
        return response
      }
    }
  }
}
</script>
