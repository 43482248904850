<template lang="pug">
  div.tabla.sombra
    .mb-2
      h3.d-inline.mr-3 Usuarios
      b-button.boton(v-b-modal.create-user-form-modal) Ingresar Nuevo
    b-table(:fields="fields" :items="userData")
      template(#cell(actions)="{value}")
        b-button.mr-2.mb-1.boton-editar(@click="openEditModal(value)")
          .material-icons create
        b-button.mb-1.boton-eliminar(@click="onDeleteUser(value)")
          .material-icons delete_outline
      template(v-slot:cell(name)="{item}")
        .columna {{item.name}}
    b-modal#create-user-form-modal(ref="createUserFormModal" hide-footer title="Creando Usuario")
      user-form(v-if="userList" @done="onDoneUserModal" :defaultValues="{ role: 'store', storeId }")
      user-form(v-else @done="onDoneUserModal")
    b-modal(ref="updateUserFormModal" hide-footer title="Editando Usuario")
      user-form(v-if="selectedUser" @done="onDoneUserModal" :user="selectedUser" :editMode="true" :defaultValues="selectedUser.storeId ? { role: 'store' } : {}")
</template>

<script>
import UserForm from './UserForm'
import { mapActions, mapGetters } from 'vuex'
import roles from '../../common/roles'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    userList: Array,
    storeId: Number
  },
  components: {
    UserForm
  },
  data () {
    return {
      selectedUser: null,
      fields: [
        { label: 'Nombre', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Rol', key: 'role' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listUser', 'deleteUser']),
    onDoneUserModal () {
      this.listUser()
      this.$refs.createUserFormModal.hide()
      this.$refs.updateUserFormModal.hide()
      this.$emit('done')
    },
    async onDeleteUser (user) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar al usuario ${user.name} ${user.lastName}?`)
      if (!ok) return

      const response = await this.deleteUser({ id: user.id })
      if (response.status === 200) {
        this.makeSuccessToast('El usuario se eliminó correctamente')
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar usuario')
      }
      await this.fetchData()
    },
    openEditModal (user) {
      this.selectedUser = user
      this.$refs.updateUserFormModal.show()
    },
    async fetchData () {
      if (!this.userList) {
        await this.listUser()
      }
    }
  },
  computed: {
    ...mapGetters(['internalUsers']),
    userData () {
      const users = this.userList ? this.userList : this.internalUsers
      return users.reduce((acum, user) => acum.concat({
        name: `${user.name} ${user.lastName}`,
        role: roles.find(role => role.value === user.role).text,
        email: user.email,
        actions: user
      }), [])
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
