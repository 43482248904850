<template lang="pug">
  #exception-view
    b-container(style="margin-top:40px")
      list-exception(v-if="user.role === 'admin'")
</template>
<script>
import ListException from '../components/exceptions/ListException'
import { mapGetters } from 'vuex'

export default {
  name: 'ExceptionsView',
  components: {
    ListException
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>
