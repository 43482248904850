<template lang="pug">
  .login-view
    .login-view-container
      .h-100.row.align-items-center
        login.col-sm-12.col-md-4
</template>

<script>
import Login from '../components/auth/Login'

export default {
  components: {
    Login
  }
}
</script>
<style lang="scss">
.login-view {
  padding: 1rem;
  height:100%;
  background: linear-gradient(to bottom,  hsla(0, 0%, 58%, 1), hsla(0, 0%, 20%, 1));
  &-container {
    height: 100%;
  }
}
.login {
  margin: auto;
}
</style>
