<template lang="pug">
  div.tabla.sombra
    .mb-2
        h3.d-inline.mr-3 Excepciones
        b-button.boton(v-b-modal.create-exception-form-modal) Ingresar Nueva
    b-table(:fields="fields" :items="exceptionData" v-if="exceptionData")
      template(#cell(actions)="{value}")
        b-button.mr-2.mb-1.boton-editar(@click="openEditModal(value)")
          .material-icons create
        b-button.mb-1.boton-eliminar(@click="onDeleteException(value)")
          .material-icons delete_outline
      template(v-slot:cell(comment)="{item}")
        .columna {{item.comment}}
    b-modal#create-exception-form-modal(ref="createExceptionFormModal" hide-footer title="Creando Tienda")
      exception-form(@done="onDoneExceptionModal")
    b-modal(ref="updateExceptionFormModal" hide-footer title="Editando Tienda")
      exception-form(@done="onDoneExceptionModal" :exception="selectedException" :editMode="true")
</template>

<script>
import ExceptionForm from './ExceptionForm'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  name: 'ListException',
  mixins: [MakeToastMixin],
  components: {
    ExceptionForm
  },
  data () {
    return {
      selectedException: null,
      fields: [
        { label: 'Fecha', key: 'date' },
        { label: 'Admite retiro', key: 'allowRemove' },
        { label: 'Comentario', key: 'comment' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listExceptions', 'deleteException']),
    onDoneExceptionModal () {
      this.listExceptions()
      this.$refs.createExceptionFormModal.hide()
      this.$refs.updateExceptionFormModal.hide()
    },
    async onDeleteException (exception) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar la excepcion ${exception.comment}?`)
      if (!ok) return

      const response = await this.deleteException({ id: exception.id })
      if (response.status === 200) {
        this.makeSuccessToast('La excepción se eliminó correctamente')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar la excepción')
      }
      await this.listExceptions()
    },
    openEditModal (exception) {
      this.selectedException = exception
      this.$refs.updateExceptionFormModal.show()
    }
  },
  computed: {
    ...mapGetters(['exceptions']),
    exceptionData () {
      return this.exceptions?.reduce((acum, exception) => acum.concat({
        date: this.$moment(exception.date.substring(0, 10)).format('DD-MM-YYYY'),
        allowRemove: exception.allowRemove ? 'SI' : 'NO',
        comment: exception.comment,
        actions: exception
      }), [])
    }
  },
  created () {
    this.listExceptions()
  }
}
</script>
