import axios from '../../plugins/axios'

export const listWithdrawal = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: payload ? payload.params : null
  }
  commit('SET_WITHDRAWALS', { withdrawals: [] })
  return axios.get('/withdrawal', config)
    .then((response) => {
      commit('SET_WITHDRAWALS', { withdrawals: response.data })
      return response
    })
    .catch(err => err.response)
}

export const getWithdrawal = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  commit('SET_WITHDRAWAL', { withdrawal: null })
  return axios.get(`/withdrawal/${payload.id}`, config)
    .then((response) => {
      commit('SET_WITHDRAWAL', { withdrawal: response.data })
      return response
    })
    .catch(err => err.response)
}

export const createWithdrawal = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post('/withdrawal', payload.data, config)
    .then(response => response)
    .catch(err => {
      console.log(err)
      payload.vm.$bvToast.toast('Ya esta agendado este retiro', { variant: 'danger' })
    })
}

export const deleteWithdrawal = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.delete(`/withdrawal/${payload.id}`, config)
    .then(response => response)
    .catch(err => err.response)
}

export const updateWithdrawal = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/withdrawal/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const getWithdrawalCountStates = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: payload ? payload.params : null
  }
  return axios.get('/withdrawal-state-count', config)
    .then(response => {
      commit('SET_WITHDRAWAL_COUNT_STATES', response.data)
      return response
    })
    .catch(err => err.response)
}

export const checkWithdrawalHour = () => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.get('/check-withdrawal-hour', config)
    .then(response => {
      return response.data
    })
    .catch(err => err.response)
}
