const mutations = {
  'SET_WITHDRAWALS' (state, { withdrawals }) {
    state.withdrawals = withdrawals
  },
  'SET_WITHDRAWAL' (state, { withdrawal }) {
    state.withdrawal = withdrawal
  },
  'SET_WITHDRAWAL_COUNT_STATES' (state, countStatesWithdrawal) {
    state.countStatesWithdrawal = countStatesWithdrawal
  }
}

export default mutations
