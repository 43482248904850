<template lang="pug">
  #app
    navbar(v-if="['admin', 'store'].includes(user ? user.role : null)")
    mobile-navbar(v-if="['delivery'].includes(user ? user.role : null)")
    // FooterMobileTab(v-if="['delivery'].includes(user ? user.role : null)")
    router-view
</template>

<script>
import Navbar from './components/UI/Navbar'
import MobileNavbar from './components/UI/MobileNavbar'
import FooterMobileTab from './components/UI/FooterMobileTab'
import { mapGetters } from 'vuex'
import '../src/styles/global.scss'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    Navbar,
    MobileNavbar,
    FooterMobileTab
  },
  methods: {
    checkAuth () {
      const { isPublic, roles } = this.$route.meta
      const name = this.$route.name
      if (isPublic) {
        if (this.user && name === 'Login') {
          this.$router.push({ name: 'Home' })
        }
      } else if (!this.user) {
        this.$router.push({ name: 'Login' })
      } else if (roles && !roles.includes(this.user.role)) {
        this.$router.push({ name: 'Home' })
      }
    },
    restoreUserFromLocalStorage () {
      const storedUser = JSON.parse(window.localStorage.getItem('user'))
      if (storedUser && storedUser.user.email) {
        this.$store.commit('SET_USER', { user: storedUser })
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'mapTab'])
  },
  watch: {
    async user () {
      this.checkAuth()
    },
    $route () {
      this.checkAuth()
    }
  },
  mounted () {
    this.checkAuth()
  },
  created () {
    this.restoreUserFromLocalStorage()
  }
}
</script>
