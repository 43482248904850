<template lang="pug">
  #CreateDriverDelivery.mt-3
    b-container
      b-card
        delivery-form(:driverId="user.id" :date="$moment().format('YYYY-MM-DD')" noIncudeHide)
</template>

<script>
import DeliveryForm from '../components/delivery/DeliveryForm'
import { mapGetters } from 'vuex'

export default {
  components: {
    DeliveryForm
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>
