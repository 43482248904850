<template lang="pug">
  b-container(style="margin-top:40px")
    store-detail
</template>

<script>
import StoreDetail from '../components/store/StoreDetail'

export default {
  components: {
    StoreDetail
  }
}
</script>
