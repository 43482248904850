<template lang="pug">
  b-container(style="margin-top:40px")
    delivery-driver-detail
</template>

<script>
import DeliveryDriverDetail from '../components/delivery/DeliveryDriverDetail'

export default {
  components: {
    DeliveryDriverDetail
  }
}
</script>
