export const deliveries = state => state.deliveries
export const delivery = state => state.delivery
export const finalRoute = state => state.finalRoute
export const undeliveredDeliveries = state => state.deliveries.reduce((acum, d) => d.state === null ? acum.concat(d) : acum, [])
export const deliveredDeliveries = state => state.deliveries.reduce((acum, d) => d.state === 'entregado' ? acum.concat(d) : acum, [])
export const countStates = state => state.countStates
export const loading = state => state.loading
export const loadDriverRoutes = state => state.loadDriverRoutes
export const mapTab = state => state.mapTab
export const deliveryTab = state => state.deliveryTab
export const editDelivery = state => state.editDelivery
export const AutocompleteAddressess = state => state.AutocompleteAddressess
