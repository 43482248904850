import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const StoreStore = {
  state: {
    withdrawals: [],
    withdrawal: {},
    countStatesWithdrawal: null
  },
  getters,
  mutations,
  actions
}

export default StoreStore
