<template lang="pug">
  b-container(style="margin-top:40px")
    list-store
</template>

<script>
import ListStore from '../components/store/ListStore'

export default {
  components: {
    ListStore
  }
}
</script>
