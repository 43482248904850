const mutations = {
  'SET_DELIVERIES' (state, { deliveries }) {
    state.deliveries = deliveries
  },
  'SET_DELIVERY' (state, { delivery }) {
    state.delivery = delivery
  },
  'SET_FINAL_ROUTE' (state, { finalRoute }) {
    state.finalRoute = finalRoute
  },
  'SET_COUNT_STATES' (state, { countStates }) {
    state.countStates = countStates
  },
  'LOCAL_OPTIMIZING_ROUTES' (state) {
    state.deliveries = state.deliveries.sort((a, b) => (a.order - b.order))
  },
  'SET_LOADING' (state, loading) {
    state.loading = loading
  },
  'SET_LOAD_DRIVER_ROUTES' (state, loadDriverRoutes) {
    state.loadDriverRoutes = loadDriverRoutes
  },
  'SET_MAP_TAB' (state, mapTab) {
    state.mapTab = mapTab
  },
  'SET_DELIVERY_TAB' (state, deliveryTab) {
    state.deliveryTab = deliveryTab
  },
  'SET_EDIT_DELIVERY' (state, editDelivery) {
    state.editDelivery = editDelivery
  },
  'SET_AUTOCOMPLETE_ADDRESSESS' (state, AutocompleteAddressess) {
    console.log(AutocompleteAddressess)
    state.AutocompleteAddressess = AutocompleteAddressess
  }
}
export default mutations
