<template lang="pug">
  .recover-password-view
    .recover-password-view-container
      .h-100.row.align-items-center
        recover-password.col-sm-12.col-md-4
</template>
<script>
import RecoverPassword from '../components/auth/RecoverPassword'

export default {
  components: {
    RecoverPassword
  }
}
</script>
<style lang="scss">
.recover-password-view{
  padding: 1rem;
  height: 100%;
  background: linear-gradient(0deg, #1F1F1F,#939393);
  &-container {
    height: 100%;
  }
}
.recover-password {
  margin: auto;
}
</style>
