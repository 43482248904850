<template lang="pug">
  b-form#DeliveryChangeDriverForm(@submit.stop.prevent="onSubmit")
    b-form-group(invalid-feedback="Este campo es requerido")
      .input-title Conductor
      b-form-select#driver.input.w-100(
        v-model="form.driverId"
        :state="$v.$dirty ? !$v.form.driverId.$invalid : null"
      )
        b-form-select-option(:value="null") Selecciona un conductor
        b-form-select-option(v-for="driver in drivers" :value="driver.id") {{ driver.name }} {{ driver.lastName }}
    hr.linea
    .text-right
      b-button(type="submit" :disabled="loading") Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    deliveryId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      form: {
        driverId: null
      }
    }
  },
  validations: {
    form: {
      driverId: { required }
    }
  },
  methods: {
    ...mapActions(['listUser', 'updateDelivery']),
    async onSubmit () {
      this.loading = true
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        const ok = await this.$root.$bvModal.msgBoxConfirm('¿Seguro que quieres cambiar el conductor?')
        if (ok) {
          const response = await this.updateDelivery({ id: this.deliveryId, data: { driverId: this.form.driverId } })
          if (response.status >= 200 && response.status < 300) {
            this.makeSuccessToast(response.data.message || 'El conductor se actualizó correctamente')
            this.$emit('done')
          } else if (response.status >= 400) {
            this.makeDangerToast(response.data.message || 'Error al cambiar el conductor')
          }
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['drivers'])
  },
  created () {
    this.listUser()
  }
}
</script>
