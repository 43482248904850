import axios from '../../plugins/axios'

export const listUser = ({ commit }) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.get('/user', config)
    .then((response) => {
      commit('SET_USERS', { users: response.data })
      return response
    })
    .catch(err => err.response)
}

export const createUser = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post('/user', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const deleteUser = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.delete(`/user/${payload.id}`, config)
    .then(response => response)
    .catch(err => err.response)
}

export const updateUser = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/user/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}
