const mapsLinks = (lat, lng) => {
  const waze = `https://ul.waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location`
  const googleMaps = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`
  return {
    waze,
    googleMaps
  }
}

export default mapsLinks
