<script>
import { mapActions } from 'vuex'
import MakeToastMixin from '../../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  methods: {
    ...mapActions(['bulkUpdateDelivery']),
    async onBulkUpdateDeliveryMixin (data) {
      const { deliveriesIds = [] } = data
      if (deliveriesIds.length === 0) {
        this.makeDangerToast('Debe seleccionar los envíos')
        return
      }
      const response = await this.bulkUpdateDelivery({ data: data })
      if (response.status >= 200 && response.status < 300) {
        this.makeSuccessToast('Los envíos se guardaron correctamente')
        response.ok = true
      } else if (response.status >= 400) {
        this.makeDangerToast('Error al guardar los envíos')
        response.ok = false
      }
      return response
    }
  }
}
</script>
