import Vue from 'vue'
import Vuex from 'vuex'
import AuthenticationStore from './authentication'
import UserStore from './user'
import StoreStore from './store'
import DeliveryStore from './delivery'
import ExceptionStore from './exceptions'
import WithdrawalStore from './withdrawal'
import VueDraggable from 'vue-draggable'

Vue.use(VueDraggable)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AuthenticationStore,
    UserStore,
    StoreStore,
    DeliveryStore,
    ExceptionStore,
    WithdrawalStore
  }
})
