<template lang="pug">
  #StoreDetail.box.sombra
    h3(v-if="store") Tienda: {{store.name}}
    store-form(
      v-if="store"
      :store="store"
      :editMode="true"
      :key="store.id"
      @done="getStore({ id: $route.params.id })"
    )
    list-user(v-if="store" :storeId="store.id" :userList="store.users" @done="getStore({ id: $route.params.id })" style="margin-top:50px")
</template>

<script>
import StoreForm from './StoreForm'
import ListUser from '../user/ListUsers'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StoreForm,
    ListUser
  },
  methods: {
    ...mapActions(['getStore'])
  },
  computed: {
    ...mapGetters(['store'])
  },
  created () {
    this.getStore({ id: this.$route.params.id })
  }
}
</script>
