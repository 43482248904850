<template lang="pug">
  b-form(@submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input calendar_today
      b-form-group(
        label-for="date-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Fecha de la excepción
        b-form-input#date-input.input(
          v-model="form.date"
          type="date"
          :state="$v.$dirty ? !$v.form.date.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input rate_review
      b-form-group(
        label-for="comment-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Comentario
        b-form-input#comment-input.input(
          v-model="form.comment"
          maxlength="255"
          :state="$v.$dirty ? !$v.form.comment.$invalid : null"
        )
    hr.linea
    b-form-checkbox.input-title(v-model="form.allowRemove") Admite retiro
    .text-right
      b-button.boton(type="submit" ) Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  name: 'ExceptionForm',
  mixins: [MakeToastMixin],
  props: {
    exception: {
      type: Object,
      default: null
    },
    editMode: Boolean
  },
  data () {
    return {
      form: {
        date: this.editMode ? this.$moment(this.exception.date.substring(0, 10)).format('YYYY-MM-DD') : this.exception?.date,
        allowRemove: this.editMode ? this.exception.allowRemove : false,
        comment: this.editMode ? this.exception.comment : ''
      }
    }
  },
  validations: {
    form: {
      date: { required },
      comment: { required }
    }
  },
  computed: {
    ...mapGetters(['exceptions'])
  },
  methods: {
    ...mapActions(['createException', 'updateException']),
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          this.onUpdateException()
        } else {
          this.onCreateException()
        }
      }
    },
    async onCreateException () {
      const duplicate = this.checkDuplicateException()
      if (duplicate) return
      const response = await this.createException({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('La excepción se creó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear la excepción')
      }
    },
    async onUpdateException () {
      const response = await this.updateException({ id: this.exception.id, data: this.form })
      if (response.status === 200) {
        this.makeSuccessToast('La excepción se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar la excepción')
      }
    },
    checkDuplicateException () {
      const alreadyExist = this.exceptions.find((e) => {
        return e.date.substring(0, 10) === this.form.date
      })
      if (alreadyExist) {
        this.makeDangerToast('Ya hay una excepción en la fecha indicada')
        return true
      } else {
        return false
      }
    }
  }
}
</script>
