<template lang="pug">
  #HomeDriver
    div(v-show="!mapTab").search-form
      b-form-input(v-model="search" placeholder="Buscar")
      .material-icons(v-if="!search.length") search
      .material-icons(v-else @click="search = ''") clear
      .material-icons(@click="optimizeRoutes()") refresh
    b-tabs(justified align="center" content-class="container" v-show="!mapTab")
        b-tab(:title="`POR ENTREGAR (${countStates['por entregar']?countStates['por entregar']:'?'})`" @click="deliveryStateFilter = 'NULL'")
        b-tab(:title="`ENTREGADOS (${countStates['entregado']?countStates['entregado']:'?'})`"  @click="deliveryStateFilter = 'entregado'")
    b-container.mt-3
      #envios(v-show="deliveryTab")
        list-driver-delivery(
          :deliveries="filteredDeliveries"
          :draggable="deliveryStateFilter==='NULL' && search === ''"
          @changeDriver="onChangeDriver"
          @editFinalRoute="showModal=true"
        )
      #mapa(v-if="mapTab")
        DeliveryMap(:filterState="deliveryStateFilter")
    // FooterMobileTab
    b-modal(ref="deliveryChangeDriverFormModal" title="Cambiar Conductor" hide-footer)
      delivery-change-driver-form(v-if="selectedDeliveryId" :deliveryId="selectedDeliveryId" @done="endChangeDriver")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ListDriverDelivery from '../delivery/ListDriverDelivery'
import DeliveryChangeDriverForm from '../delivery/DeliveryChangeDriverForm'
import DeliveryMap from '../delivery/DeliveryMap'
// import FooterMobileTab from '../UI/FooterMobileTab'

export default {
  components: {
    ListDriverDelivery,
    DeliveryChangeDriverForm,
    DeliveryMap
    // FooterMobileTab
  },
  data () {
    return {
      selectedDeliveryId: null,
      deliveryStateFilter: 'NULL',
      optimize: false,
      search: '',
      showModal: false
    }
  },
  methods: {
    ...mapActions(['listDriverDelivery', 'getCountStates']),
    ...mapMutations(['LOCAL_OPTIMIZING_ROUTES']),
    fetchData () {
      this.listDriverDelivery({
        driverId: this.user.id,
        params: {
          date: this.$moment().format('YYYY-MM-DD'),
          state: this.deliveryStateFilter,
          orderBy: this.deliveryStateFilter === 'NULL' ? 'order' : undefined,
          orderType: this.deliveryStateFilter === 'NULL' ? 'ASC' : undefined,
          optimizingRoutes: this.optimize
        }
      })
      this.getCountStates({ params: { date: this.$moment().format('YYYY-MM-DD') } })
    },
    onChangeDriver (delivery) {
      this.selectedDeliveryId = delivery.id
      this.$refs.deliveryChangeDriverFormModal.show()
    },
    endChangeDriver () {
      this.selectedDeliveryId = null
      this.$refs.deliveryChangeDriverFormModal.hide()
      this.fetchData()
    },
    async optimizeRoutes () {
      this.optimize = true
      await this.fetchData()
      this.optimize = false
    },
    localOptimizing () {
      this.fetchData()
      if (this.deliveries) this.LOCAL_OPTIMIZING_ROUTES()
    }
  },
  computed: {
    ...mapGetters(['user', 'deliveries', 'countStates']),
    ...mapGetters(['loading', 'mapTab', 'deliveryTab']),
    filteredDeliveries () {
      if (this.search !== '' && this.search !== null) {
        return this.deliveries.filter(delivery => {
          let text = `${delivery.store ? delivery.store.name : 'No encontrado'}
          ${delivery.address.street}
          ${delivery.address.commune}
          ${delivery.customerName}
          #${delivery.id}`

          text = text.toUpperCase()
          return text.includes(this.search.toUpperCase())
        })
      }
      const deliveries = this.deliveries
      let count = 0
      for (let i = 0; i < this.deliveries.length; i++) {
        if (this.deliveries[i].order !== -100) {
          deliveries.order = count
          count = count + 1
        }
      }
      return deliveries
    }
  },
  watch: {
    deliveryStateFilter () {
      if (this.deliveryStateFilter === 'NULL') this.showModal = false
      this.fetchData()
    },
    loading (value) {
      if (value) {
        /** const count = this.deliveries.length
        localStorage.setItem('optimize', JSON.stringify(count)) **/
        /* if (this.deliveries) {
          const finalRoute = [...this.deliveries].filter((d) => d.order === -1000)
          if (finalRoute.length === 0) {
            this.showModal = true
          }
        } */
      }
    }
  },
  created () {
    this.deliveryStateFilter = 'NULL'
    /* if (localStorage.getItem('optimize')) {
      const deliveries = parseInt(localStorage.getItem('optimize'))
      if (deliveries && this.deliveries.length) return this.localOptimizing()
    } */
    this.optimizeRoutes()
  }
}
</script>

<style lang="scss" scoped>
#HomeDriver {
  padding-bottom: 1rem;
  .search-form {
    color: white;
    background: linear-gradient(to right, hsla(0, 0%, 58%, 1), hsla(0, 0%, 40%, 1));
    display: flex;
    align-content: center;
    .form-control, .form-control::placeholder {
      background: transparent;
      border-color: transparent;
      box-shadow: none;
      color: white;
    }
    .material-icons {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .content {
    height: 70vh;
    overflow-y: scroll;
  }
}
</style>
