<template lang="pug">
  b-container(style="margin-top:40px")
    list-users
</template>

<script>
import ListUsers from '../components/user/ListUsers'

export default {
  components: {
    ListUsers
  }
}
</script>
