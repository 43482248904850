<template lang="pug">
  #delivery-view
    b-container(style="margin-top:40px")
      list-delivery(v-if="user.role === 'admin'")
      list-store-delivery(v-if="user.role === 'store'")
</template>
<script>
import ListDelivery from '../components/delivery/ListDelivery'
import ListStoreDelivery from '../components/delivery/ListStoreDelivery'
import { mapGetters } from 'vuex'

export default {
  components: {
    ListDelivery,
    ListStoreDelivery
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>
