import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const DeliveryStore = {
  state: {
    deliveries: [],
    delivery: {},
    finalRoute: null,
    countStates: {},
    loading: false,
    loadDriverRoutes: false,
    mapTab: false,
    deliveryTab: true,
    editDelivery: false,
    AutocompleteAddressess: []
  },
  getters,
  mutations,
  actions
}

export default DeliveryStore
