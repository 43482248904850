<template lang="pug">
  b-container(style="margin-top:40px")
    withdrawal-form
</template>

<script>
import WithdrawalForm from '../components/withdrawal/WithdrawalForm.vue'

export default {
  name: 'CreateWithdrawalView',
  components: {
    WithdrawalForm
  }
}
</script>
