import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const ExceptionStore = {
  state: {
    exceptions: []
  },
  getters,
  mutations,
  actions
}

export default ExceptionStore
