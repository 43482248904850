<template lang="pug">
#all-packages-label-template.d-none
  PackageLabelTemplate(v-for="p in packages" :key="p.id" :context="p")
</template>

<script>
import { Printd } from 'printd'
import PackageLabelTemplate from './PackageLabelTemplate.vue'
import css from './css'

export default {
  components: {
    PackageLabelTemplate
  },
  data () {
    return {
      packages: []
    }
  },
  methods: {
    print (packages) {
      this.packages = packages
      // console.log(packages)
      this.$nextTick(() => {
        const d = new Printd()
        d.print(document.getElementById('all-packages-label-template'), [css])
      })
    }
  }
}
</script>
