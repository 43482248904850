<template lang="pug">
  b-container(style="margin-top:40px")
    list-withdrawal
</template>

<script>
import ListWithdrawal from '../components/withdrawal/ListWithdrawal.vue'

export default {
  name: 'WithdrawalView',
  components: {
    ListWithdrawal
  }
}
</script>
