<template lang="pug">
  b-form(@submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input store
      .group
        b-form-group(
          label-for="name-tienda-input"
          invalid-feedback="Este campo es requerido"
        )
          .input-title Nombre de la Tienda
          b-form-input#name-tienda-input.input(
            v-model="form.name"
            maxlength="256"
            :state="$v.$dirty ? !$v.form.name.$invalid : null"
          )
    hr.linea
    .box-form
      .material-icons.icon-input place
      .group
        b-form-group(
          label-for="region-input"
        )
          .input-title Región
          b-form-input#region-input.input(
            v-model="form.address.region"
            maxlength="256"
          )
    hr.linea
    .box-form
      .material-icons.icon-input store
      .group
        b-form-group(
          label-for="commune-input"
        )
          .input-title Comuna
          b-form-input#commune-input.input(
            v-model="form.address.commune"
            maxlength="256"
          )
    hr.linea
    .box-form
      .material-icons.icon-input store
      .group
        b-form-group(
          label-for="street-input"
        )
          .input-title Calle
          b-form-input#street-input.input(
            v-model="form.address.street"
            maxlength="256"
          )
    hr.linea
    .box-form
      .material-icons.icon-input store
      .group
        b-form-group(
          label-for="street-number-input"
        )
          .input-title Número de calle
          b-form-input#street-number-input.input(
            v-model="form.address.streetNumber"
            type="number"
          )
    hr.linea
    .box-form
      .material-icons.icon-input store
      .group
        b-form-group(
          label-for="apartment-input"
        )
          .input-title Detalle: bloque / depto / oficina
          b-form-input#apartment-input.input(
            v-model="form.address.apartment"
            placeholder="Ej: depto 301"
            maxlength="256"
          )
    hr.linea
    .box-form
      .material-icons.icon-input store
      .group
        b-form-group(
          label-for="lat-coordinate-input"
        )
          .input-title Latitud
          b-form-input#lat-coordinate-input.input(
            v-model="form.address.latCoordinate"
            maxlength="256"
          )
    hr.linea
    .box-form
      .material-icons.icon-input store
      .group
        b-form-group(
          label-for="long-coordinate-input"
        )
          .input-title Longitud
          b-form-input#long-coordinate-input.input(
            v-model="form.address.longCoordinate"
            maxlength="256"
          )
    hr.linea
    b-form-checkbox.input-title(v-model="form.isActive") Activo
    .text-right
      b-button.boton(type="submit" ) Guardar
</template>

<script>
import roles from '../../common/roles'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    store: {
      type: Object,
      default: null
    },
    editMode: Boolean
  },
  data () {
    return {
      form: {
        name: this.editMode ? this.store.name : '',
        isActive: this.editMode ? this.store.isActive : true,
        address: {
          region: this.editMode ? this.store.address.region : '',
          commune: this.editMode ? this.store.address.commune : '',
          street: this.editMode ? this.store.address.street : '',
          streetNumber: this.editMode ? this.store.address.streetNumber : null,
          apartment: this.editMode ? this.store.address.apartment : '',
          comment: this.editMode ? this.store.address.comment : '',
          latCoordinate: this.editMode ? this.store.address.latCoordinate : '',
          longCoordinate: this.editMode ? this.store.address.longCoordinate : ''
        }
      },
      roles: roles
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  methods: {
    ...mapActions(['createStore', 'updateStore']),
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          this.onUpdateUser()
        } else {
          this.onCreateUser()
        }
      }
    },
    async onCreateUser () {
      const response = await this.createStore({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('La tienda se creó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear la tienda')
      }
    },
    async onUpdateUser () {
      const response = await this.updateStore({ id: this.store.id, data: this.form })
      if (response.status === 200) {
        this.makeSuccessToast('La tienda se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar la tienda')
      }
    }
  }
}
</script>
