const getMonthString = (date) => {
  const day = date.getUTCDate()
  let month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()
  if (month === 1) {
    month = 'Ene'
  }
  if (month === 2) {
    month = 'Feb'
  }
  if (month === 3) {
    month = 'Mar'
  }
  if (month === 4) {
    month = 'Abr'
  }
  if (month === 5) {
    month = 'May'
  }
  if (month === 6) {
    month = 'Jun'
  }
  if (month === 7) {
    month = 'Jul'
  }
  if (month === 8) {
    month = 'Ago'
  }
  if (month === 9) {
    month = 'Sep'
  }
  if (month === 10) {
    month = 'Oct'
  }
  if (month === 11) {
    month = 'Nov'
  }
  if (month === 12) {
    month = 'Dic'
  }
  return `${day} ${month}, ${year}`
}

export default getMonthString
