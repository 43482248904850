<template lang="pug">
  #WithdrawalStateCards
    .row
      .col-md-3.mb-2
        state-count-card(
          stateName = "Planillas Faltantes"
          color="#3498DB"
          icon="pending_actions"
          :stateCount="countStatesWithdrawal['no asignados']"
        )
      .col-md-3.mb-2
        state-count-card(
          stateName = "Planillas OK"
          color="#3DF9DE"
          icon="checklist"
          :stateCount="countStatesWithdrawal['asignados']"
        )
      .col-md-3.mb-2
        state-count-card(
          stateName = "Planillas totales"
          color="#9B59B6"
          icon="content_paste"
          :stateCount="countStatesWithdrawal['todos']"
        )
</template>

<script>
import StateCountCard from '../UI/StateCountCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'WithdrawalStateCards',
  components: {
    StateCountCard
  },
  computed: {
    ...mapGetters(['countStatesWithdrawal'])
  }
}
</script>
