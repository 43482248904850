import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/bootstrap-vue/index'
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import './plugins/fontawesome'
import './registerServiceWorker'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

const debounce = (callback, delay = 1500) => {
  let timeout
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(callback, delay)
  }
}

Vue.prototype.$debounce = debounce

Vue.use(VueMoment, {
  moment
})
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
