import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faWhatsapp as fabWhatsapp
} from '@fortawesome/free-brands-svg-icons'

library.add(
  fabWhatsapp
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
