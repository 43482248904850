<template lang="pug">
  #Maps
    div(id="map" v-if="DeliveriesOptimize.length > 0")
      div(id="googlemap" style="width: 100%; height: 70vh")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mapsLinks from '../../shared/mapsLinks'
import settings from '../../../settings'

export default {
  name: 'GoogleMaps',
  props: ['filterState'],
  async created () {
    await this.listDriverDelivery({
      driverId: this.user.id,
      params: {
        date: this.$moment().format('YYYY-MM-DD'),
        state: '',
        orderBy: 'order',
        orderType: 'ASC',
        optimizingRoutes: true
      }
    })
    this.createdMap()
  },
  data () {
    return {
      map: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  async mounted () {
    if (this.DeliveriesOptimize.length > 0) {
      this.createdMap()
    }
  },
  computed: {
    ...mapGetters(['deliveries', 'user']),
    DeliveriesOptimize () {
      if (this.deliveries.length > 0) {
        return this.deliveries.filter(d => d.order !== -100 && d.address.latCoordinate && d.address.longCoordinate).map((d) => {
          return {
            position: {
              lat: d.address.latCoordinate ? parseFloat(d.address.latCoordinate) : this.CenterMap.lat,
              lng: d.address.longCoordinate ? parseFloat(d.address.longCoordinate) : this.CenterMap.lng
            },
            info: d
          }
        })
      }
      return []
    },
    CenterMap () {
      if (this.deliveries.length > 1) {
        const filter = this.deliveries.filter(d => d.order !== -100 && d.address.latCoordinate && d.address.longCoordinate).map((d) => {
          return {
            position: {
              lat: d.address.latCoordinate ? parseFloat(d.address.latCoordinate) : this.CenterMap.lat,
              lng: d.address.longCoordinate ? parseFloat(d.address.longCoordinate) : this.CenterMap.lng
            },
            info: d
          }
        })
        return filter[0].position
      }
      return {}
    }
  },
  methods: {
    ...mapActions(['listDriverDelivery']),
    pinSymbol (color) {
      return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 2,
        scale: 1
      }
    },
    async createdMap () {
      const key = settings.mapsKey

      // create the script element to load
      const googleMapScript = document.createElement('SCRIPT')
      googleMapScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`
      )
      googleMapScript.setAttribute('defer', '')
      googleMapScript.setAttribute('async', '')
      document.head.appendChild(googleMapScript)
      this.initMap()
    },
    initMap () {
      const self = this
      window.initMap = () => {
        this.map = new window.google.maps.Map(document.getElementById('googlemap'), {
          mapTypeId: 'roadmap',
          zoom: 11,
          streetViewControl: false,
          center: this.CenterMap
        })
        self.loadMarkers()
      }
    },
    GoToDelivery (id) {
      this.$router.push(`envios/${id}`)
    },
    loadMarkers () {
      const vm = this
      window.GoToDelivery = function (id) {
        vm.GoToDelivery(id)
      }
      this.DeliveriesOptimize.forEach((d, index) => {
        const links = mapsLinks(d.position.lat, d.position.lng)
        const mapMarker = new window.google.maps.Marker({ // eslint-disable-line 
          position: new window.google.maps.LatLng(
            d.position.lat,
            d.position.lng
          ),
          map: this.map,
          icon: d.info.state !== null ? this.pinSymbol('green') : this.pinSymbol('red')
        })
        mapMarker.infoWindow = new window.google.maps.InfoWindow({ // eslint-disable-line 
          content: `
            <div class="row">
              <div class="col d-flex flex-column">
                <div class="mb-2">
                  <span style="color:#495057;font-weight:600"s>Envio #${d.info.id} ${d.info.state !== null ? '(Entregado)' : '(Por entregar)'}</span>
                <div>
                <div class="mt-2 mb-2 d-flex flex-row">
                  <div class="d-flex flex-column">
                    <div style="font-size: 16px; font-weight: bold;"> ${d.info.address.street}</div>
                    <div style="font-size: 16px; font-weight: bold;">${d.info.address.commune}</div>
                  </div>
                  <div class="ml-2 d-flex flex-row">
                    <span class="ml-2 mr-1"><a href="${links.waze}" target="_blank">
                      <img src="https://image.flaticon.com/icons/png/512/732/732141.png" style="height: 2em; width: 2em;"></img>
                    </a></span>
                    <a href="${links.googleMaps}" target="_blank">
                      <img style="height: 2em; width: 2em;" src="https://image.flaticon.com/icons/png/512/2991/2991231.png"/>
                    </a>
                  </div>
                </div>
                <div class="mt-2 text-light-primary">
                  ${d.info.customerName}
                </div>
                <div class="d-flex flex-row mt-1">
                  <span style="color: #00C5AC">${d.info.customerPhone}</span>
                    <span class="ml-auto">
                      <button onclick="GoToDelivery(${d.info.id})"style="font-size: 12px;" class="btn btn-secondary" type="button"> Ver todo </button>
                    </span>
                </div>
              </div>
              <div class ="col">
              </div>
            </div>
          `
        })
        mapMarker.addListener('click', () => {
          if (mapMarker.infoWindow) mapMarker.infoWindow.open(this.map, mapMarker)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.google_maps {
  background: uri("../../../public/img/waze.svg");
  width: 50px;
  height: 50px;
  display: block;
}
</style>
