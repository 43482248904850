<template lang="pug">
  #Footer
    .row.mt-2
      .col
        div(
          :class="deliveryTab? 'd-flex justify-content-center flex-column active' : 'd-flex justify-content-center flex-column'"
          @click="setDeliveries()")
          .material-icons local_shipping
          span Envios
      .col
        div(
          :class="mapTab? 'd-flex justify-content-center flex-column active' : 'd-flex justify-content-center flex-column'"
          @click="setMap()")
          .material-icons-outlined place
          span Mapa
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'FooterMobileTab',
  computed: {
    ...mapGetters(['mapTab', 'deliveryTab'])
  },
  methods: {
    ...mapMutations(['SET_MAP_TAB', 'SET_DELIVERY_TAB']),
    setMap () {
      if (this.deliveryTab) {
        this.SET_DELIVERY_TAB(false)
        this.SET_MAP_TAB(true)
      }
    },
    setDeliveries () {
      if (this.mapTab) {
        this.SET_MAP_TAB(false)
        this.SET_DELIVERY_TAB(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    -webkit-box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.85);
    box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.85);
    background-color: white;
  }
  .active {
    color: #00C5AC;
  }
</style>
