<template lang="pug">
  b-container(style="margin-top:40px")
    delivery-detail(:hideDriver="user.role === 'store'")
</template>

<script>
import DeliveryDetail from '../components/delivery/DeliveryDetail'
import { mapGetters } from 'vuex'

export default {
  components: {
    DeliveryDetail
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>
